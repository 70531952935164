<template>
  <section v-if="tableau">
    <TitleBar v-if="tableau.attributes.titre" class="mb-0">
      {{ tableau.attributes.titre }}
    </TitleBar>
    <!-- Affichage des alertes -->
    <template v-for="alerte in tableau.attributes.alertes">
      <StrapiAlerteComponent
        :key="`alerte-${alerte.id}`"
        :item="alerte"
      ></StrapiAlerteComponent>
    </template>
    <b-container fluid class="mt-3">
      <!-- Affichage des widgets hors contacts -->
      <template v-for="(item, index) in autresWidgets">
        <component
          :key="index"
          :is="item.type.component"
          :item="item"
        ></component>
      </template>
    </b-container>
    <!-- Affichage du widget contacts -->
    <b-container v-if="contactWidget" fluid class="contact-widget">
      <ContactsWidget :item="contactWidget"></ContactsWidget>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../components/TitleBar.vue";
import StrapiAlerteComponent from "../components/strapi/StrapiAlerteComponent.vue";
import ActualitesWidget from "../components/widgets/ActualitesWidget.vue";
import ComptaWidget from "../components/widgets/ComptaWidget.vue";
import ContactsWidget from "../components/widgets/ContactsWidget.vue";
import DeclarationsWidget from "../components/widgets/DeclarationsWidget.vue";
import DocumentsWidget from "../components/widgets/DocumentsWidget.vue";
import IndicateursWidget from "../components/widgets/IndicateursWidget.vue";
import LibreWidget from "../components/widgets/LibreWidget.vue";
import StrapiService from "../services/strapi.service";
export default {
  name: "StrapiTableauView",
  components: {
    TitleBar,
    StrapiAlerteComponent,
    ActualitesWidget,
    ComptaWidget,
    ContactsWidget,
    DeclarationsWidget,
    DocumentsWidget,
    IndicateursWidget,
    LibreWidget,
  },
  data() {
    return {
      tableau: null,
    };
  },
  computed: {
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    contactWidget() {
      return this.tableau.attributes.contenu.find(
        (item) => item.__component === "tableau.contacts"
      );
    },
    autresWidgets() {
      return this.tableau.attributes.contenu.filter(
        (item) => item.__component !== "tableau.contacts"
      );
    },
  },
  async mounted() {
    let response = await StrapiService.getTableaux(this.exploitationCourante.codeSociete);
    let tableau = response.data.data[0];
    tableau.attributes.contenu = tableau.attributes.contenu
      .map((widget) => {
        widget.type = this.$store.getters["strapi/composant"](widget.__component);
        return widget;
      })
      .filter((widget) => widget.type?.access(this.$store) === true);
    this.tableau = tableau;
  },
};
</script>
